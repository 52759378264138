@import "../../styles/_common.scss";

.expired {
    @include center;
    text-align: center;

    img {
        width: 100%;
        max-width: 200px;
        margin-bottom: -16px;
    }
}
