@mixin padding-x($left, $right: $left) {
    padding-right: $right;
    padding-left: $left;
}

@mixin padding-y($top, $bottom: $top) {
    padding-top: $top;
    padding-bottom: $bottom;
}

@mixin margin-x($left, $right: $left) {
    margin-right: $right;
    margin-left: $left;
}

@mixin margin-y($top, $bottom: $top) {
    margin-top: $top;
    margin-bottom: $bottom;
}

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
