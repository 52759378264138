@import "../assets/fonts/Roboto/index.scss";
@import "_common.scss";

html,
body {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

h3 {
    @include font(subhead);
}

textarea {
    font-family: inherit;
}
