$font: (
    subhead: (
        weight: 400,
        size: 20,
    ),
    large: (
        weight: 400,
        size: 16,
    ),
    body: (
        weight: 400,
        size: 14,
    ),
    small: (
        weight: 400,
        size: 12,
    ),
    extraSmall: (
        weight: 400,
        size: 10,
    ),
    detail: (
        weight: 300,
        size: 8,
    ),
    headline: (
        weight: 500,
        size: 32,
    ),
    headlineLight: (
        weight: 300,
        size: 32,
    ),
    subheadLight: (
        weight: 300,
        size: 20,
    ),
    largeLight: (
        weight: 300,
        size: 16,
    ),
    bodyLight: (
        weight: 300,
        size: 14,
    ),
    smallLight: (
        weight: 300,
        size: 12,
    ),
);

@mixin font($name, $style: normal) {
    @if map-has-key($font, $name) {
        $fontValues: map-get($font, $name);
        $fontWeight: map-get($fontValues, weight);
        $fontSize: map-get($fontValues, size);
        font-weight: $fontWeight;
        font-size: #{$fontSize}px;
        font-style: $style;
    }

    @warn "Unknown font `#{$name}`";
}
