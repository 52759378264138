@mixin src($variant) {
    src: url("./Roboto-#{$variant}.ttf");
}

@mixin font-face($variant, $weight, $style: normal) {
    @font-face {
        @include src($variant);
        font-weight: $weight;
        font-family: Roboto;
        font-style: $style;
    }
}

@include font-face("Light", 300);
@include font-face("LightItalic", 300, italic);
@include font-face("Regular", 400);
