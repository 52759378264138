@keyframes customEnterAnimation {
  0% {
    transform: translateY(80vh);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes customLeaveAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(80vh);
  }
}

.fade {
  position: absolute;
  height: 100vh;
  width: 100%;
  top:0;
  background-color: rgba(0,0,0,0.4);
  transition: all .375s;
}

.fadeIn {
  opacity: 1;
  visibility: visible;
}
.fadeOut {
  visibility: hidden;
  opacity: 0;
}

.customOverlay {
  background: transparent;
  padding:0
}

.customModal {
  background: #fff;
  border-radius: 8px;
  max-width: 100%;
  width: 100%;
  height:80vh;
  margin-bottom:revert;
  margin-top:auto;
}
